// Construct our "home" and "error" page GROQ
export const homeID = `*[_type=="generalSettings"][0].home->_id`;
export const contactID = `*[_type=="generalSettings"][0].contact->_id`;
export const errorID = `*[_type=="generalSettings"][0].error->_id`;

// Construct our "page" GROQ
const page = `
  "type": _type,
  "slug": slug.current,
  "isHome": _id == ${homeID}
`;

// Construct our "link" GROQ
const link = `
  _key,
  _type,
  title,
  url,
  "page": page->{
    ${page}
  }
`;

// Construct our "image meta" GROQ
export const imageMeta = `
  "alt": coalesce(alt, asset->altText),
  asset,
  crop,
  customRatio,
  hotspot,
  "id": asset->assetId,
  "type": asset->mimeType,
  "aspectRatio": asset->metadata.dimensions.aspectRatio,
  "lqip": asset->metadata.lqip,
  mixBlendMode
`;

// Construct our "portable text content" GROQ
export const ptContent = `
  ...,
  markDefs[]{
    ...,
    _type == "link" => {
      "url": @.url,
      "isButton": @.isButton,
      "styles": @.styles{style, isLarge, isBlock},
      "page":@.page->{
        ${page}
      }
    }
  },
  _type == "photo" => {
    ${imageMeta}
  }
`;

// Construct our "site" GROQ
export const site = `
  "site": {
    "title": *[_type == "generalSettings"][0].siteTitle,
    "cookieConsent": *[_type == "cookieSettings"][0]{
      enabled,
      message,
      "link": link->{"type": _type, "slug": slug.current}
    },
    "seo": *[_type == "seoSettings"][0]{
      metaTitle,
      metaDesc,
      shareTitle,
      shareDesc,
      shareGraphic,
      "favicon": favicon.asset->url,
      "faviconLegacy": faviconLegacy.asset->url,
      touchIcon
    },
    "footerCtaLabel": *[_type == "generalSettings"][0].footerCtaLabel,
    "footerText": *[_type == "generalSettings"][0].footerText,
    "footerLinks": *[_type == "generalSettings"][0].footerLinks,
  }
`;

// Construct our content "modules" GROQ
export const modules = `
  _type == 'hero' => {
    _type,
    _key,
    style,
    images[] {
      ${imageMeta}
    },
    phrase
  },
  _type == 'introText' => {
    _type,
    _key,
    style,
    text,
    removeMinHeight,
    cta[] {
      _type,
      _key,
      ${link}
    },
    pricingBlocks[] {
      label,
      
      value,
      backgroundColor,      
    },
    pricingAdditionalText[] {
      ${ptContent}
    },
    backgroundColor,
    image {
      ${imageMeta}
    }
  },
  _type == 'interactive' => {
    _type,
    _key,
    style,
    backgroundColor,
    reduceMargin,
    reduceMinHeight,
    sections[] {
      _type,
      phrase,
      phraseMaxWidth,
      body[] {
        ${ptContent}
      },
      mediaStyle,
      media[] {
        photo {
          ${imageMeta}
        },
        vimeoId,
        maxWidth
      },
      caption
    }
  },
  _type == 'textAndMedia' => {
    _type,
    _key,
    style,
    sectionTitle,
    includeScaleAnimation,
    body[] {
      ${ptContent}
    },
    phrase,
    media {
      photo {
        ${imageMeta}
      },
      vimeoId
    }
  },
  _type == 'gallery' => {
    _type,
    _key,
    sectionTitle,
    sectionBody,
    images[] {
       ${imageMeta}
    },
    cta[] {
      _type,
      _key,
      ${link}
    },
  },
  _type == 'featureCarousel' => {
    _type,
    _key,
    phrase,
    slides[] {
      slideLabel,
      slideBody,
      media {
        photo {
          ${imageMeta}
        },
        vimeoId
      }
    }
  },
  _type == 'diagramTabs' => {
    _type,
    _key,
    largerMobileAspectRatio,
    diagrams[] {
      phrase,
      title,
      includeRules,
      labels[] {
        title,
        additionalText,
        xOffset,
        yOffset,
        xOffsetMobile,
        yOffsetMobile
      },
      image {
        ${imageMeta}
      },
      backgroundColor
    }
  },
   _type == 'expanders' => {
    _type,
    _key,
    phrase,
    image {
      ${imageMeta}
    },
    introText[] {
      ${ptContent}
    },
    expanders[] {
      label,
      sublabel,
      body[] {
        ${ptContent}
      },
      specs[] {
        title,
        text,
        part
      }
    }
  },
  _type == 'contactForm' => {
    _type,
    _key,
    introText,
    primaryImage {
      ${imageMeta}
    }
  },
  _type == 'spacer' => {
    _type,
    _key,
    size
  },
  _type == 'grid' => {
    _type,
    _key,
    style,
    phrase,
    backgroundColor,
    gridBlocks[] {
      title,
      titlePlacement,
      figure,
      animateFigure,
      image {
        ${imageMeta}
      },
      backgroundColor,
      body[] {
        ${ptContent}
      },
    }
  },
  _type == 'cta' => {
    _type,
    _key,
    sectionTitle,
    backgroundColor,
    cta[] {
      _type,
      _key,
      ${link}
    },
  },
  _type == 'articleListing' => {
    _type,
    _key,
    sectionTitle,
    backgroundColor,
    dwellRssUrl
  },
  _type == 'partners' => {
    _type,
    _key,
    sectionTitle,
    backgroundColor,
    logos[] {
      url,
      image {
        ${imageMeta}
      }
    }
  }
`;
