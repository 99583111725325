import { createClient } from "next-sanity";
import { config } from "@lib/config";
import sanityImage from "@sanity/image-url";

// Set up the client for fetching data in the getProps page functions
export const sanityClient = createClient(config);
export const imageBuilder = sanityImage(sanityClient);

// Set up a preview client with serverless authentication for drafts
export const previewClient = createClient({
  ...config,
  useCdn: false,
  token: process.env.SANITY_API_READ_TOKEN,
});

// Helper function for easily switching between normal client and preview client
export const getSanityClient = (usePreview) =>
  usePreview ? previewClient : sanityClient;
