import React, { createContext, useContext, useEffect, useState } from "react";

// get our API clients (shopify + sanity)
import { getSanityClient } from "@lib/sanity.server";

// get our global image GROQ
import { queries } from "@data";

// Set our initial context states
const initialContext = {
  isPageTransition: false,
  isLoading: true,
  currentSiteTheme: "gray",
};

// Set context
const SiteContext = createContext({
  context: initialContext,
  setContext: () => null,
});

/*  ------------------------------ */
/*  Our Context Wrapper
/*  ------------------------------ */

const SiteContextProvider = ({ data, children }) => {
  const [context, setContext] = useState({
    ...initialContext,
  });

  const [initContext, setInitContext] = useState(false);

  return (
    <SiteContext.Provider
      value={{
        context,
        setContext,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

// Access our global store states
function useSiteContext() {
  const { context } = useContext(SiteContext);
  return context;
}

// Update site theme
function useUpdateSiteTheme() {
  const {
    context: { currentSiteTheme },
    setContext,
  } = useContext(SiteContext);

  async function updateSiteTheme(color = "gray") {
    if (color == false) {
      color = "gray";
    }
    setContext((prevState) => {
      return {
        ...prevState,
        currentSiteTheme: color,
      };
    });
  }
  return updateSiteTheme;
}

// Toggle page transition state
function useTogglePageTransition() {
  const {
    context: { isPageTransition },
    setContext,
  } = useContext(SiteContext);

  async function togglePageTransition(state) {
    setContext((prevState) => {
      return { ...prevState, isPageTransition: state };
    });
  }
  return togglePageTransition;
}

export {
  SiteContextProvider,
  useSiteContext,
  useUpdateSiteTheme,
  useTogglePageTransition,
};
